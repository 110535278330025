import React, {useState} from 'react';
import LoginForm from './LoginForm';
import TopBar from "./components/TopBar";
import SideBar from "./components/SideBar";
import {Route, Routes, useLocation} from "react-router-dom";
import OverView from "./pages/OverView";
import RtbGeo from "./pages/RtbGeo";
import OverViewMonth from "./pages/OverViewMonth";
import PackageSettings from "./pages/PackageSettings";
import AppList from "./pages/AppList";
import './styles/dashboard.scss'
import './styles/sideBar.scss'
import './styles/topBar.scss'
import open_sidebar from './img/open_sidebar.svg';
import GeoSettings from "./pages/GeoSettings";
import GeoAdwords from "./pages/GeoAdwords";

const App = () => {
    const location = useLocation();
    const { pathname } = location;
    const [valueSelect, setValueSelect] = useState({});
    const isAuthenticated = localStorage.getItem('authToken');
    const [isOpen, setIsOpen] = useState(false);
    const handleTrigger = () => setIsOpen(!isOpen);

    return (
        <div>
            {isAuthenticated ? (
                <div className={
                    (pathname.split("/")[1] !== "geo_settings" && pathname.split("/")[1] !== "package_settings")
                        ? isOpen ? "dashboard "+pathname.split("/")[1] : "dashboard hide"
                    : "dashboard"
                }>
                    {(pathname.split("/")[1] !== "geo_settings" || pathname.split("/")[1] !== "package_settings")
                        ? <TopBar selectState={[valueSelect, setValueSelect]} openParameter={[isOpen, setIsOpen]}/>
                        : null}
                    {
                    (pathname.split("/")[1] !== "geo_settings" && pathname.split("/")[1] !== "package_settings")
                        ? isOpen ? <SideBar openState={[isOpen, setIsOpen]}/> : <img src={open_sidebar} className="open_sidebar" onClick={handleTrigger}/>
                        : <SideBar openState={[isOpen, setIsOpen]}/>
                    }
                    <Routes>
                        <Route path="/" element={<OverViewMonth dataSelect={valueSelect} openSideBar={[isOpen, setIsOpen]}/>} />
                        <Route path="/overview_month" element={<OverViewMonth dataSelect={valueSelect} openSideBar={[isOpen, setIsOpen]}/>}/>
                        <Route path="/overview" element={<OverView openSideBar={[isOpen, setIsOpen]}/>}/>
                        <Route path="/app_list" element={<AppList openSideBar={[isOpen, setIsOpen]}/>}/>
                        <Route path="/geo_settings" element={<GeoSettings/>}/>
                        <Route path="/geo_adwords" element={<GeoAdwords openSideBar={[isOpen, setIsOpen]}/>}/>
                        <Route path="/package_settings" element={<PackageSettings/>}/>
                    </Routes>
                </div>
            ) : (
                <div className="login_page">
                    <LoginForm />
                </div>
            )}
        </div>
    );
};

export default App;