import React from "react";
import {hide} from "react-modal/lib/helpers/ariaAppHider";

const TableAdwords = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [sortParameter, setSortParameter] = parameters.sort;
    const [activeItemIndex, setActiveItemIndex] = parameters.activeItemIndex;

    function sort(name, itemNum) {
        if(name+'-' === sortParameter) {
            setSortParameter(name);
        } else  {
            setSortParameter(name+'-');
        }
        setActiveItemIndex(itemNum);
        setLoading(true);
    }

    function hidePackageInfoTable(packageName) {
        console.log(packageName)
    }

    return (
        <div>
            <div className="title_table">App list</div>
            <div className="table_wrapper">
                <table>
                    <tbody>
                    <tr>
                        <th className="application_name">Application</th>
                        <th className={activeItemIndex === 1 ? sortParameter === 'ads_installs' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ads_installs', 1)}>Installs Adwords
                        </th>
                        <th className={activeItemIndex === 2 ? sortParameter === 'CPI_adwords' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('CPI_adwords', 2)}>CPI Adwords
                        </th>
                        <th className={activeItemIndex === 3 ? sortParameter === 'new_subs' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('new_subs', 3)}>Trials
                        </th>
                        <th className={activeItemIndex === 4 ? sortParameter === 'ARPI_12m' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ARPI_12m', 4)}>ARPI 12m
                        </th>
                        <th className={activeItemIndex === 5 ? sortParameter === 'ARPI_ad' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ARPI_ad', 5)}>ARPI Ad
                        </th>
                        <th className={activeItemIndex === 6 ? sortParameter === 'ROI' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ROI', 6)}>ROI
                        </th>
                    </tr>
                    {parameters.data.map((item, key) => {
                        if (item.available) {
                            return (
                                <>
                                    <tr key={'parent' + key}>
                                        <td className="name_package_adwords" onClick={e => hidePackageInfoTable(item.package)}>
                                            <img className="package_image"
                                                 src={'https://api.morn-overview.com' + item.icon} alt=''/>
                                            <div className="computer_comment_package_adwords">
                                                <div className="package">{item.package}</div>
                                                <div className="sub_package">
                                                    <span className="computer">{
                                                        item.computer ? '(' + item.computer + ')' : null
                                                    }</span>
                                                    <span className="comment">{item.comment}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <>
                                        {item.geo.map((value, valueKey) => {
                                            return (
                                                <tr key={valueKey}>
                                                    <td>
                                                        <div className="code_country_name">
                                                            <span className="code">{value.code}</span>
                                                            <span className="country_name">{value.country_name}</span>
                                                        </div>
                                                    </td>
                                                    <td>{value.ads_installs ?? '-'}</td>
                                                    <td>{value.CPI_adwords ? value.CPI_adwords + ' $' : '-'}</td>
                                                    <td>{value.new_subs ?? '-'}</td>
                                                    <td>{value.ARPI_12m ? value.ARPI_12m + ' $' : '-'}</td>
                                                    <td>{value.ARPI_ad ? value.ARPI_ad + ' $' : '-'}</td>
                                                    <td>{value.ROI ? value.ROI + ' %' : '-'}</td>
                                                </tr>
                                            )
                                        })}
                                    </>

                            </>)
                        }
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableAdwords;