import axios from 'axios';

const API_URL = 'https://api.morn-overview.com';

export const ApiPostRequest =  async (url, parameters)  => {
    try {
        const response = await axios.post(
            `${API_URL}/${url}`,
            parameters,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('authToken'),
                },
            }
        );
        return response.data;

    } catch (error) {
        console.error('Error loading data:', error);
        throw error;
    }
};