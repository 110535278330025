import inactive from "../../img/inactive.svg";
import React from "react";

const TableAppList = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [sortParameter, setSortParameter] = parameters.sort;
    const [activeItemIndex, setActiveItemIndex] = parameters.activeItemIndex;

    function percentRender(percent) {
        if(percent) {
            if(String(percent).search('-') === 0) {
                return (<span className="minus">{String(percent).replace('-', '')} %</span> )
            }
            return (<span className="plus">{percent+ ' %'}</span> )
        }
    }

    function sort(name, itemNum) {
        if('-'+name === sortParameter) {
            setSortParameter(name);
        } else  {
            setSortParameter('-'+name);
        }
        setActiveItemIndex(itemNum);
        setLoading(true);
    }

    return (
        <div>
            <div className="total">
                <table>
                    <tbody>
                    <tr>
                        <th className="total__first_col">Total</th>
                        <th className="position">Total Installs</th>
                        <th>Installs Adwords</th>
                        <th>Total income</th>
                        <th className="position">Total cost</th>
                        <th className="position">Profit</th>
                        <th className="position">ROI</th>
                    </tr>
                    <tr>
                        <td className="total__first_col"></td>
                        <td>
                            {parameters.data.Total.total_gp_installs ?? '-'}
                            <span
                                className="percent">{percentRender(parameters.data.Total.total_gp_installs_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Total.ads_installs ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Total.ads_installs_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Total.Total_income ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Total.Total_income_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Total.Total_cost ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Total.Total_cost_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Total.Profit ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Total.Profit_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Total.ROI ? parameters.data.Total.ROI + ' %' : '-'}
                            <span className="percent">{percentRender(parameters.data.Total.ROI_percent)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="title_table">App list</div>

            <div className="total">
                <table>
                    <tbody>
                    <tr>
                        <th className="total__first_col">Sum</th>
                        <th className="position">Total Installs</th>
                        <th>Installs Adwords</th>
                        <th>Total income</th>
                        <th className="position">Total cost</th>
                        <th className="position">Profit</th>
                        <th className="position">ROI</th>
                    </tr>
                    <tr>
                        <td className="total__first_col"></td>
                        <td>
                            {parameters.data.Sum.total_gp_installs ?? '-'}
                            <span
                                className="percent">{percentRender(parameters.data.Sum.total_gp_installs_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Sum.ads_installs ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Sum.ads_installs_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Sum.Total_income ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Sum.Total_income_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Sum.Total_cost ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Sum.Total_cost_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Sum.Profit ?? '-'}
                            <span className="percent">{percentRender(parameters.data.Sum.Profit_percent)}</span>
                        </td>
                        <td>
                            {parameters.data.Sum.ROI ? parameters.data.Sum.ROI + ' %' : '-'}
                            <span className="percent">{percentRender(parameters.data.Sum.ROI_percent)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="app_list_table">
                <table>
                    <tbody>
                    <tr>
                        <th className="aplication">Application</th>
                        <th className={activeItemIndex === 1 ? sortParameter === 'total_gp_installs' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('total_gp_installs', 1)}>Total Installs
                        </th>
                        <th className={activeItemIndex === 2 ? sortParameter === 'ads_installs' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ads_installs', 2)}>Installs Adwords
                        </th>
                        <th className={activeItemIndex === 3 ? sortParameter === 'Total_income' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('Total_income', 3)}>Total income
                        </th>
                        <th className={activeItemIndex === 4 ? sortParameter === 'Total_cost' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('Total_cost', 4)}>Total cost
                        </th>
                        <th className={activeItemIndex === 5 ? sortParameter === 'Profit' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('Profit', 5)}>Profit
                        </th>
                        <th className={activeItemIndex === 6 ? sortParameter === 'ROI' ? "sortItem active asc" : "sortItem active" : "sortItem"}
                            onClick={e => sort('ROI', 6)}>ROI
                        </th>
                    </tr>
                    {parameters.data.App_list.map((item, key) => {
                        if (item.available) {
                            return (
                                <tr key={key}>
                                    <td className="app_list_table__aplication">
                                        <div className="aplication_content">
                                            <img
                                                className="app_list_table__image"
                                                src={item.icon ? 'https://api.morn-overview.com' + item.icon : inactive}
                                                alt=""
                                            />
                                            <div className="content">
                                                <div className="content__app_name">{item.package}</div>
                                                <div className="computer_comment">
                                                    <span className="computer">
                                                        {item.computer ? '(' + item.computer + ') ' : null}
                                                    </span>
                                                    <span className="comment">
                                                         {item.comment}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.total_gp_installs ?? '-'}
                                            <span
                                                className="percent">{percentRender(item.total_gp_installs_percent)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.ads_installs ?? '-'}
                                            <span className="percent">{percentRender(item.ads_installs_percent)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.Total_income ?? '-'}
                                            <span className="percent">{percentRender(item.Total_income_percent)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.Total_cost ?? '-'}
                                            <span className="percent">{percentRender(item.Total_cost_percent)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.Profit ?? '-'}
                                            <span className="percent">{percentRender(item.Profit_percent)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.ROI ? item.ROI + ' %' : '-'}
                                            <span className="percent">{percentRender(item.ROI_percent)}</span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TableAppList;